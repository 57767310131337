import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AnalyticsType } from 'src/app/enums/analytics-type';
import { UpdateLikeType } from 'src/app/enums/update-like-type';
import { Project } from 'src/app/interfaces/project';
import { Update } from 'src/app/interfaces/update';
import { ContentTextPipe } from 'src/app/pipes/content-text.pipe';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { ReadMorePipe } from '../../../../pipes/read-more.pipe';
import { TruncateModule } from '@yellowspot/ng-truncate';
import { PollComponent } from '../../../poll/poll.component';
import { SocialButtonsComponent } from '../../../social-button/social-buttons.component';
import { MediaSliderComponent } from '../../../media-slider/media-slider.component';
import { EventsComponent } from '../../../events/events.component';
import { NgIf } from '@angular/common';
import { TranslateTextDirective } from '../../../../directives/translate-text.directive';

@Component({
  selector: 'app-card-update',
  templateUrl: './card-update.component.html',
  standalone: true,
  imports: [
    TranslateTextDirective,
    NgIf,
    EventsComponent,
    ContentTextPipe,
    MediaSliderComponent,
    SocialButtonsComponent,
    PollComponent,
    TruncateModule,
    ReadMorePipe,
  ],
})
export class CardUpdateComponent implements OnInit {
  isPopoverVisible: boolean = false;
  @Input() update: Update;
  @Input() project: Project;
  @Input() language: string;
  @Input() category: string;
  @Input() likedType: UpdateLikeType;
  @Output() openLink = new EventEmitter<void>();
  @ViewChild('popover') popover: ElementRef;

  constructor(
    private analyticsService: AnalyticsService,
    private contentTextPipe: ContentTextPipe
  ) {}

  ngOnInit() {
    this.update['contentText'] = this.contentTextPipe.transform(
      this.update.content
    );
  }

  open() {
    this.openLink.next();
  }

  toggleReadMore() {
    if (this.update['expanded'] == undefined) {
      this.analyticsService.logEvent(
        AnalyticsType.UPDATE_OPEN,
        {
          update_id: this.update.id,
        },
        this.project
      );
    }
    this.update['expanded'] = true;
  }

  getTruncateLength(): number {
    if (this.update['expanded']) {
      return (
        this.update['translatedContent']?.length ||
        this.update['contentText'].length
      );
    } else {
      return 300;
    }
  }
}
