import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { WhiteLabelApplication } from '../interfaces/white-label-application';
import { HttpClient } from '@angular/common/http';
import { PwaService } from './pwa.service';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { delay, filter, first, retry, retryWhen, scan } from 'rxjs/operators';
import { ToastService } from './toast.service';
import { SplashScreen } from '@capacitor/splash-screen';

@Injectable({
  providedIn: 'root',
})
export class WhiteLabelApplicationService {
  static APP = 'app';
  static APP_EXPIRY = 'app_expiry';
  public currentWhiteLabelApp: WhiteLabelApplication;
  public ready$ = new BehaviorSubject(false);

  constructor(
    private httpClient: HttpClient,
    private pwaService: PwaService,
    private toastService: ToastService
  ) {}

  public async check() {
    if (!this.pwaService.isPwa()) {
      return this.fetchDataForApp();
    } else {
      return this.fetchDataForPwa().catch(() => this.fetchDataForApp());
    }
  }

  public waitUntilReady() {
    return this.ready$
      .pipe(
        filter((ready) => ready),
        first()
      )
      .toPromise();
  }

  private async fetchDataForPwa() {
    const origin = window.location.hostname.split(':')[0];
    this.currentWhiteLabelApp = (await lastValueFrom(
      this.httpClient.get(
        `${environment.baseURL}/api/v3/pwa?pwaDomain=${origin}&pwa=true`
      )
    )) as WhiteLabelApplication;

    if (this.currentWhiteLabelApp) {
      this.ready$.next(true);
    } else {
      throw new Error('PWA-domain not found, falling back to app.');
    }
  }

  private async fetchDataForApp() {
    let retryCount = 0;
    try {
      this.currentWhiteLabelApp = (await lastValueFrom(
        this.httpClient
          .get(
            `${environment.baseURL}/api/v3/apps?identifier=${environment.whiteLabelApplicationKey}`
          )
          .pipe(
            retryWhen((errors) =>
              errors.pipe(
                scan((retry) => {
                  retryCount += 1;
                  if (retryCount === 3) {
                    SplashScreen.hide();
                    this.toastService.showOffline();
                  }
                  return retry;
                }),
                delay(1000) // Wait for 1 second between retries
              )
            )
          )
      )) as WhiteLabelApplication;
    } catch (error) {
      // ignore
    }

    if (this.currentWhiteLabelApp) {
      this.toastService.hideOffline();
      this.ready$.next(true);
    }
  }

  public initialize() {
    if (!this.currentWhiteLabelApp) {
      return;
    }

    document.documentElement.style.setProperty(
      '--appTint',
      this.currentWhiteLabelApp.primaryColor
    );
    document.documentElement.style.setProperty(
      '--appSupport',
      this.currentWhiteLabelApp.secondaryColor
    );

    const css = this.currentWhiteLabelApp.customStyleSheet;

    if (!!css) {
      const style = document.createElement('style');
      style.innerHTML = css;
      document.head.appendChild(style);
    }

    const favIcon = document.getElementById('favicon');
    favIcon.setAttribute(
      'href',
      this.currentWhiteLabelApp.appIconThumbnails?.small
    );
  }
}
